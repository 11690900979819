<template>
    <div class="">
        <a-spin :spinning="loading">
            <div class="flex space alcenter">
                <div class="ft20 cl-main ftw600">{{getTitle}}</div>
                <a-button @click="$router.go(-1)">返回</a-button>
            </div>

            <div class="mt20 bg-w pd40">
                <div style="width: 1000px;">
                    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-form-model-item required label="文章名称" help="20字以内">
                            <a-input v-model="form.name" :maxLength="30" placeholder="输入文章名称" style="width: 480px;"></a-input>
                        </a-form-model-item>

                        <a-form-model-item required label="文章分类">
                            <a-tree-select v-model="form.category_id" style="width: 200px"
                                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="categorytype_list"
                                           placeholder="请选择" >
                            </a-tree-select>
                        </a-form-model-item>


                        <a-form-model-item label="排序" help="数值越大排序越高">
                            <a-input-number v-model="form.sort" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
                        </a-form-model-item>

                        <a-form-model-item required label="是否显示">
                            <a-radio-group v-model="form.status">
                                <a-radio :value="1">是</a-radio>
                                <a-radio :value="0">否</a-radio>
                            </a-radio-group>
                        </a-form-model-item>

                        <a-form-model-item v-if="source_shop_id==0" label="审核状态">
                            <a-radio-group v-model="form.audit_status">
                                <a-radio :value="1">通过审核</a-radio>
                                <a-radio :value="0">待审核</a-radio>
                                <a-radio :value="-1">拒绝审核</a-radio>
                            </a-radio-group>
                        </a-form-model-item>

                        <a-form-model-item label="文章类型" help="系统文章：用户协议、隐私申明类，只可修改，不可删除

                            公告文章：这类文章发布后会显示在公告里，不在其他列表展示。

                        服务规范：针对服务项目进行服务人员行为规范的描述，在发布服务项目处可选。

                        营销文章：区别与系统文章、服务规范和公告文章的第四类，不限制，开放展示与文章列表">
                            <a-select   style="width: 120px" :options="type_list"  v-model="form.type">

                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item required label="文章主图" >
                            <div class="ft12 ftw400 cl-notice">建议上传300x240px</div>
                            <div class="mt10 ml20">
                                <upload-img v-model="form.thumb"></upload-img>
                            </div>
                        </a-form-model-item>
                        <div class="edit-project-line mb40"></div>
                        <a-form-model-item label="文章详情" required class="mt20">
                            <html-edit v-model="form.conent"></html-edit>
                        </a-form-model-item>
                        <a-form-model-item  label="外部链接" help="200字以内">
                            <a-input v-model="form.link_url" :maxLength="120" placeholder="输入外部链接" style="width: 480px;">
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item  label="附件">
                            <div class="edit-level-form">
                                <div class="edit-level-form-name">
                                    <a-upload
                                            name="file"
                                            :showUploadList="true"
                                            :customRequest="upload"
                                            :beforeUpload="beforeUpload"
                                            :default-file-list="defaultFileList"
                                            :file-list="fileList"
                                            accept="application/pdf,application/vnd.ms-works,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            :multiple="false"
                                    >
                                        <a-button><a-icon type="upload" />点击上传</a-button>
                                    </a-upload>
                                    <div>
                                        <a-card :bordered="false">
                                            <p v-for="(item,index) in msgArr">{{ item }}</p>
                                        </a-card>
                                    </div>

                                </div>
                            </div>

                        </a-form-model-item>
                        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                            <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                                确定保存
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>

                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import uploadImg from '../../../components/upload/upload.vue';
    import uploadList from '../../../components/upload/list.vue';
    import  htmlEdit from '../../../components/html.vue';
    export default{
        components:{
            uploadImg,
            uploadList,
            htmlEdit
        },
        data(){
            return {
                loading:false,
                confirmLoading:false,
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
                categorytype_list:[],
                msgArr:[],
                defaultFileList:[],
                fileList:[],
                article_id:0,
                type_list:[],
                source_shop_id:0,
                form: {
                    selecthttp:'https://',
                    id:0,
                    name:'',
                    category_id:0,
                    sort:'',
                    thumb:'',
                    link_url:'',
                    type:0,
                    attachment_url:'',
                    status:1,
                    conent:'',
                    audit_status:0,

                }
            }
        },
        created() {
            if (!this.$route.query.article_id) {
                this.form.id=0;
            } else {
                this.form.id = parseInt(this.$route.query.article_id);
            }

            this.loaddata();
        },

        computed:{
            getTitle(){
                if(this.article_id==0){
                    return '添加文章';
                }else{
                    return '编辑文章';
                }
            }
        },
        methods:{
            upload(e) {
                let file = e.file;
                this.loading = true;
                let  fileobject={
                    uid: file.uid,      // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
                    name: file.name,   // 文件名
                    status: 'error', // 状态有：uploading done error removed
                    url: '', //

                };
                this.fileList=[];
                this.$http.uploadfiles(file,'uploadfile').then(res=>{
                    this.loading = false;
                    if(res.code == 200){
                        let data=res.data;
                        this.$message.success(res.msg);
                        this.form.attachment_url=data.url.url;
                        fileobject.status='done';
                        fileobject.url=data.url.url;
                        fileobject.response='upload ok';
                    }else{
                        fileobject.status='error';
                        fileobject.url="";
                        this.msgArr = res.msg;
                        fileobject.response='upload error';
                    }
                    this.fileList.push(fileobject);
                }).catch(res=>{
                    this.fileList=[];
                    this.loading = false;
                });
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('最大为2M');
                    return false;
                }
                return isLt2M;
            },
            loaddata(){
                if(this.loading==true) return;
                    this.loading=true;
                    this.$http.api('platform/article/show',{
                    article_id:this.form.id
                }).then(res=>{

                    if(res.detail){
                        this.form=res.detail;

                        if(res.detail.attachment_url){
                            let defalutfile={
                                uid: '1',
                                name: res.detail.attachment_url,
                                status: 'done',
                                response: 'Server Error 500', // custom error message to show
                                url: res.detail.attachment_url,
                            };
                            console.log(defalutfile);
                          this.fileList.push(defalutfile);
                        }

                    }
                    this.type_list=res.type_list;
                    this.categorytype_list = res.category;
                    this.source_shop_id=res.shop_id;
                    this.loading=false;
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                    this.$router.go(-1);
                })
            },
            onSubmit() {
                if(this.confirmLoading==true) return;
                this.confirmLoading=true;
                this.$http.api('platform/article/save',this.form).then(res=>{
                    this.$message.success('保存成功',1,()=>{
                        this.confirmLoading=false;
                        this.$router.go(-1);
                    });
                }).catch(res=>{
                    console.log(res);
                    this.confirmLoading=false;
                })
            },

        }
    }
</script>

<style>
    .edit-project-line{
        width: 100%;
        height: 1px;
        background: #EBEDF5;
    }

    .edit-project-details-text {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #5333FF;
    }

    .mobile-content{
        width: 750px;
    }
    .mobile-content{

    }

    .mobile-item{
        position: relative;
    }
    .mobile-item .action{
        position: absolute;
        left: 0;
        top: -15px;
        width: 100%;
        z-index: 2;
    }
    .del-action{
        width: 30px;
        height: 30px;
        background: rgba(0,0,0,.5);
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
        color:#FFFFFF;
        position: absolute;
        right: -15px;
        top:-15px;
        z-index: 3;
    }
    .up-action,.down-action{
        width: 30px;
        height: 30px;
        background: #FFFFFF;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
        color:#007AFF;
        box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);

    }
</style>
